// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    serverUrl: 'http://124.70.80.208:3000/upload'
     // serverUrl: 'http://127.0.0.1:5000/upload'
  },
  getters: {
    serverUrl: state => state.serverUrl
  }
});

export default store;
