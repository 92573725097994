import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: ()=> import('../components/Login.vue') },
  {
    path: '/home',
    component: ()=> import('../views/Home.vue'),
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: ()=> import('../components/Welcome.vue') },
      { path: '/users', component: ()=> import('../components/Users.vue') },
	   { path: '/admin', component: ()=> import('../components/Admin.vue') },
      { path: '/list', component: ()=> import('../components/List.vue') },
	  {path:'/mtlist',component:()=>import('../components/MtList.vue')},
      { path: '/order', component: ()=> import('../components/Order.vue') },
      { path: '/pinglun', component: ()=> import('../components/Pinglun.vue') },
      { path: '/add', component: ()=> import('../components/Add.vue') },
	    { path: '/mtAdd', component: ()=> import('../components/MtAdd.vue') },
      { path: '/gonggao', component: ()=> import('../components/Gonggao.vue') },
      { path: '/fenlei', component: ()=> import('../components/Fenlei.vue') },
	        { path: '/video', component: ()=> import('../components/Video.vue') },
    ]
  }

]

const router = new VueRouter({
  routes
})
// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 检查用户是否已经登录
  const isLoggedIn = localStorage.getItem('isLoggedIn'); 

  if (to.path !== '/login' && !isLoggedIn) {
    // 如果用户未登录且要前往的页面不是登录页面，则重定向到登录页面
    next('/login');
  } else {
    // 允许用户正常导航
    next();
  }
});

export default router
